
import { useDcmStore } from '@/stores/dcm'
import { storeToRefs } from 'pinia'

export default {
  name: 'MPLandingLinkBadges',
  setup() {
    const dcmStore = useDcmStore()
    const { mainPageLandingLinkBadges } = storeToRefs(dcmStore)
    return { mainPageLandingLinkBadges }
  },
  computed: {
    bannerList() {
      // Prepare default data for each expected banner
      const defaultBanners = [
        {
          seqNo: 1,
          defaultImage: `/images/linkBadges/smartphones_${this.$i18n.locale}.webp`,
          defaultUrl:
            this.$i18n.locale === 'az'
              ? 'https://umico.az/categories/3-mobil-telefonlar-ve-smartfonlar'
              : 'https://umico.az/ru/categories/3-mobilnye-telefony-i-smartfony',
        },
        {
          seqNo: 2,
          defaultImage: `/images/linkBadges/loyalty-${this.$i18n.locale}.webp`,
          defaultUrl: `https://umico.az${
            this.$i18n.locale === 'az' ? '' : '/ru'
          }/loyalty-program`,
        },
        {
          seqNo: 3,
          defaultImage: `/images/linkBadges/birkart_umico_${this.$i18n.locale}.jpg`,
          defaultUrl: `https://birbank-card.umico.az${
            this.$i18n.locale === 'az' ? '/' : '/ru'
          }?utm_source=marketplace&utm_medium=banner`,
        },
      ]

      // Map over default banners & match them with store data (if any)
      return defaultBanners.map(defaultBanner => {
        const match = this.mainPageLandingLinkBadges?.items?.find(
          item => item.seq_no === defaultBanner.seqNo,
        )
        const image = match?.images
          ? match.images['1']
          : defaultBanner.defaultImage
        const url = match?.action_url || defaultBanner.defaultUrl

        return { ...match, image, url }
      })
    },
  },
  methods: {
    handleBannerClick(banner) {
      this.sendSelectPromotionEvent(banner)

      if (banner?.is_clickable && banner?.url) {
        window.location.href = banner.url
      }
    },
    formatDate(date) {
      if (!date) return null
      const d = new Date(date)
      if (isNaN(d.getTime())) return null
      const year = d.getFullYear()
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const day = ('0' + d.getDate()).slice(-2)
      return `${year}${month}${day}`
    },
    sendSelectPromotionEvent(banner) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'homepage_side_banner',
          creative_url: banner?.url || null,
          publish_date: this.formatDate(banner?.active_from) || null,
          unpublish_date: this.formatDate(banner?.active_to) || null,
          location_code: this.mainPageLandingLinkBadges.location_code || null,
          object: 'banner',
          creative_id: banner?.id || null,
        })
      }
    },
  },
}
