
import setDataLayerCategoryOpen from '@/mixins/setDataLayer'
import { setUrl } from '@/utilities'
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '@/stores/market'
export default {
  name: 'SmartProductCategoriesMenu',
  mixins: [setDataLayerCategoryOpen],
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    return {
      hover: false,
    }
  },
  async fetch() {
    if (!this.smart_category?.length) {
      const dcmStore = useDcmStore()
      await dcmStore.getSmartCategory()
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['smart_category', 'prom_prod_title']),
    listSmartCategories() {
      return this.smart_category || []
    },
    hasProducts() {
      return !!this.smart_category.length
    },
  },
  methods: {
    setUrl,
    src(category) {
      return category.images?.[4]
    },
    formatDate(date) {
      if (!date) return null
      const d = new Date(date)
      if (isNaN(d.getTime())) return null
      const year = d.getFullYear()
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const day = ('0' + d.getDate()).slice(-2)
      return `${year}${month}${day}`
    },
    handleSmartCategoryClick(category) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'catalogue_promo',
          creative_url: category?.action_url || null,
          publish_date: this.formatDate(category?.active_from) || null,
          unpublish_date: this.formatDate(category?.active_to) || null,
          location_code: category?.location_code || null,
          object: 'button',
          creative_id: category?.id || null,
          category_ids: `${category?.category_id || ''}`,
        })
      }

      this.$router.push(this.localePath(setUrl(category.action_url)))
    },
  },
}
